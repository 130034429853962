'use strict'
const {getMaxSkewYBounds} = require('../../../utils/definitionsUtils')

const name = 'BgSkew'
const angle = 20
const properties = {
    hideOnStart: false,
    getMediaDimensions(width, height) {
        return getMaxSkewYBounds(width, height, angle)
    },
    getMaxTravel(elementMeasure, viewPortHeight) {
        return viewPortHeight + elementMeasure.height
    },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, params = {}) {
        const sequence = factory.sequence(params)

        sequence.add([
            factory.animate('BaseBgSkew', elements, duration, delay, {
                in: {
                    start: 0,
                    end: 0.5,
                    skewY: angle,
                    ease: 'none'
                },
                out: {
                    start: 0.5,
                    end: 1,
                    skewY: -angle,
                    ease: 'none'
                }
            })
        ])

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
