'use strict'
const name = 'BgFadeIn'
const properties = {
    hideOnStart: false,
    //TODO: recheck this calculation
    getMaxTravel(elementMeasure, viewPortHeight, siteHeight) {
        return Math.min(
            siteHeight - elementMeasure.top,
            (viewPortHeight + elementMeasure.height) / 2,
            viewPortHeight * 0.9
        )
    },
    // getMaxTravel(elementMeasure, viewPortHeight) {
    //     return viewPortHeight + elementMeasure.height
    // },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, params = {}) {
        const sequence = factory.sequence(params)

        sequence.add(factory.animate('BaseBgFade', elements, duration, delay, {
            in: {
                start: 0,
                end: 1,
                opacity: 0,
                ease: 'sine.in'
            }
        }))

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
