'use strict'
const {getMaxRotateBounds} = require('../../../utils/definitionsUtils')

const name = 'BgRotate'
const angle = 22

const properties = {
    hideOnStart: false,
    getMediaDimensions(width, height) {
        return getMaxRotateBounds(width, height, angle)
    },
    getMaxTravel(elementMeasure, viewPortHeight) {
        return viewPortHeight + elementMeasure.height
    },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, params = {}) {
        const sequence = factory.sequence(params)

        sequence.add([
            factory.animate('BaseBgRotate', elements, duration, delay, {
                in: {
                    start: 0,
                    end: 0.65,
                    rotation: angle,
                    ease: 'sine.out'
                }
            })
        ])

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
